import $ from "jquery";

const url = 'https://graph.facebook.com/v19.0/me?fields=id,name,posts.limit(5){permalink_url,message,created_time}&access_token=EAAFNplZBJDVwBOZCMZBgAnYSNaOMMEk2NoFjz1UZBJtWidDpZBgz4YQ2CCn4arloKmEU0kEfOWpj06cmjyhVFZBluM35zTYWZCtLF9toQjFi32AcCZA4DuQGShdjqNPDj5wbbfx433InQ4hqDZBZBep23wjAPlbZCRdxK0cxnDQIlvJKxa4x2ZBkr36lVeYZBZBxn2TVEZBHgmp5GpdG3ElUDstDGaj53D0mj1ODd0v2KZAcbdGz2Ovnk1dM9NZBqZAf8GXdsdR0DaXZAVSd5PXTr4ZD';

let post,
    html = '',
    text_length,
    max_characters = 80,
    message = '',
    date = '',
    year = '',
    month = '',
    day = '',
    hour = '',
    minute = '';

$.ajax({
  dataType: 'json',
  type: 'GET',
  url: url,
})
  .done((json) => {
    post = json.posts.data;
    console.log(post);
    for (var i = 0; i < post.length; i++) {
      if((post[i].message)){
        text_length = post[i].message.length;
        if (text_length > max_characters) {
          message = post[i].message.substring(0, max_characters) + '...';
        } else {
          message = post[i].message;
        }
        date = new Date(post[i].created_time);
        // date.setTime(date.getTime() + 1000*60*60*9);
        year = date.getFullYear();
        month = date.getMonth() + 1;
        day = date.getDate();
        hour = date.getHours();
        minute = date.getMinutes();
        html += '<li><span>' + year + '/' + month + '/' + day + ' ' + hour + ':' + minute + '</span><a href="' + post[i].permalink_url + '" target="_blank">' + message + '</a></li>';
      }
    }
    $("#js-facebook").append(html);
  })
  .fail(() => {
    html += '<li></li>';
    $("#js-facebook").append(html);
  });
